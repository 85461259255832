import React from "react"
import PropTypes from "prop-types"
import { Button, Icon } from "antd"

const Banner = ({ onClose, message }) => {
  return message ? (
    <div className="banner">
      <Icon className="banner__icon" type="info-circle" size="large" />
      <div
        className="banner__message"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <Button className="banner__button" size="small" onClick={onClose} />
    </div>
  ) : null
}

Banner.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
}

export default Banner
