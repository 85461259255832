/* eslint-disable no-console,react/no-multi-comp */
import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import PageTransition from "gatsby-plugin-page-transitions"
import moment from "moment-timezone"
import { injectIntl } from "gatsby-plugin-intl"

import CustomHeader from "../components/header"
import CustomMenu from "../components/menu"
import Banner from "../components/banner"
import CustomFooter from "../components/commonFooter"
import { Layout } from "antd"

import authFetch from "../services/network"

import "antd/lib/style"
import "../assets/styles"

const APP_BANNER_LOCALSTORAGE = "appBanner"

const { Content } = Layout

export class Template extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      bannerMessage: "",
      showMenu: false,
    }
    this.appBanner = this._getAppBannerFromLocalStorage()
    this._onBannerCloseClick = this._onBannerCloseClick.bind(this)
  }

  componentDidMount() {
    authFetch(`${process.env.GATSBY_PLATFORM_API_URL}/tier`)
      .then(response => this._onTierRequestResponse(response))
      .catch(reason =>
        console.error(`[ERROR] Retrieving platform use status: ${reason}`)
      )
  }

  _onTierRequestResponse(response) {
    const status = response && response.status
    if (status === 200) {
      return response.json().then(response =>
        this.setState({
          bannerMessage: this._getUsageBannerMessage(response.item),
        })
      )
    } else if (status === 204) {
      return Promise.resolve() // no content -> ignore
    } else {
      return Promise.reject(
        `Error StatusCode: ${response.status}\n${
          response.message ? response.message : response
        }`
      )
    }
  }

  _getTierLevel(tier = "") {
    const match = tier.match(/\d+$/)
    return match && match[0] && Number(match[0])
  }

  _getUsageBannerMessage(response) {
    const { currentTier, currentPercentage, forecastTier } = response
    this.forecastTierLevel = this._getTierLevel(forecastTier)
    let shouldDisplay
    if (this.appBanner.closedByUserDate !== null) {
      if (this._isNewMonth()) {
        shouldDisplay = true
        this.appBanner.closedByUserDate = null
        this._setAppBannerIntoLocalStorage(this.appBanner)
      } else
        shouldDisplay =
          this.forecastTierLevel > this.appBanner.tierLevelOnUserClose
    } else {
      shouldDisplay = this.forecastTierLevel > this._getTierLevel(currentTier)
    }
    return shouldDisplay
      ? this._composeBannerMessage(
          currentPercentage,
          currentTier,
          this.forecastTierLevel
        )
      : ""
  }

  _composeBannerMessage(currentPercentage, currentTier, forecastTierLevel) {
    return this.props.intl.formatMessage(
      {
        id: "banner",
      },
      {
        currentPercentage: Math.ceil(currentPercentage),
        currentTier: currentTier,
        forecastTier: forecastTierLevel,
      }
    )
  }

  _isNewMonth() {
    const currentMonth = moment().month()
    const closedByUserDateMonth = moment(
      this.appBanner.closedByUserDate
    ).month()

    return currentMonth > closedByUserDateMonth
  }

  _getAppBannerFromLocalStorage() {
    const _localStorage =
      typeof window !== "undefined" && window && window.localStorage
    let appBanner =
      _localStorage && _localStorage.getItem(APP_BANNER_LOCALSTORAGE)
    if (appBanner === null) {
      appBanner = {
        tierLevelOnUserClose: 1,
        closedByUserDate: null,
      }
      //First save
      this._setAppBannerIntoLocalStorage(appBanner)
      return appBanner
    } else {
      return JSON.parse(appBanner)
    }
  }

  _setAppBannerIntoLocalStorage(appBanner) {
    const _localStorage =
      typeof window !== "undefined" && window && window.localStorage
    _localStorage &&
      _localStorage.setItem(APP_BANNER_LOCALSTORAGE, JSON.stringify(appBanner))
  }

  _onBannerCloseClick() {
    this.appBanner.closedByUserDate = moment().toISOString()
    this.appBanner.tierLevelOnUserClose = this.forecastTierLevel
    this._setAppBannerIntoLocalStorage(this.appBanner)
    this.setState({ bannerMessage: "" })
  }

  render() {
    return (
      <div className="site">
        <Helmet>
          <html className={null} />
          <title itemProp="name" lang="es">
            Knolar | {this.props.selected[0].toUpperCase()}
          </title>
        </Helmet>
        <Layout>
          <Banner
            message={this.state.bannerMessage}
            onClose={this._onBannerCloseClick}
          />
          <CustomHeader
            toggleShowMenu={() =>
              this.setState({ showMenu: !this.state.showMenu })
            }
          />
          <Layout>
            <CustomMenu
              selected={this.props.selected}
              collapsed={this.props.collapsed}
              visible={this.state.showMenu}
            />
            <Content>
              {this.state.showMenu ? (
                <div
                  className="menu-blocker"
                  onClick={() => this.setState({ showMenu: false })}
                />
              ) : (
                ""
              )}
              <div style={{ minHeight: "calc(100vh - 150px)" }}>
                <PageTransition>{this.props.children}</PageTransition>
              </div>
              <CustomFooter />
            </Content>
          </Layout>
        </Layout>
      </div>
    )
  }
}

Template.propTypes = {
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool,
  selected: PropTypes.array,
}

Template.defaultProps = {
  selected: ["home"],
  collapsed: false,
}

export default injectIntl(Template)
